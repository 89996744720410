import React, { lazy, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';

import { CONFIG } from './global';
import { Config } from './core/interfaces';
import TRANSLATIONS from './translations';
import { updateConstants } from './utils/constants';

function AppRoot() {
    const [app, setApp] = useState<React.JSX.Element>();
    const searchParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        let configParam: string | undefined = '';
        let strictConfig: boolean = false;
        if (window.getApplicationParametersMap) {
            strictConfig =
                !window.location.hostname.startsWith(
                    'dev.xcelerator.siemens.com'
                ) &&
                !window.location.hostname.startsWith(
                    'stage.xcelerator.siemens.com'
                ) &&
                !window.location.hostname.startsWith('wwwdev.siemens.com');
            configParam = window
                .getApplicationParametersMap(
                    document.getElementById('root-booth-map')
                )
                .get('config')
                ?.toLowerCase();
        }
        if (!configParam) {
            configParam = searchParams.get('config')?.toLowerCase();
        }

        fetch(`${import.meta.env.BASE_URL}config.json`)
            .then(response => response.json())
            .then(json => {
                let config: Config | undefined;
                if ((json as Array<Config>).length) {
                    const index = json.findIndex(
                        (c: Config) => c.fair?.toLowerCase() === configParam
                    );
                    if (!(strictConfig && index === -1)) {
                        config = json[index >= 0 ? index : 0];
                    }
                } else if (!strictConfig || json.fair === configParam) {
                    config = json;
                }

                if (config) {
                    if (config.fair && !document.title.includes(config.fair)) {
                        document.title = `${document.title} ${config.fair}`;
                    }

                    Object.assign(CONFIG, config);

                    // update constants
                    if ((CONFIG as Config).constants) {
                        updateConstants((CONFIG as Config).constants!);
                    }

                    const Application = lazy(() => import('./App'));

                    setApp(<Application />);
                } else {
                    setApp(
                        <h2
                            style={{
                                width: '100%',
                                height: '100%',
                                fontSize: '3vmax',
                                textAlign: 'center',
                                padding: '20vmin',
                                lineHeight: '4vmax',
                            }}
                        >
                            {
                                TRANSLATIONS[
                                    searchParams.get('lang') || 'en-US'
                                ].errorConfig
                            }
                        </h2>
                    );
                }
            })
            .catch(err => {
                throw new Error(`Unable to load the application: ${err}`);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return app;
}

const CONTAINER =
    document.getElementById('root-booth-map') ||
    document.getElementById('root-booth-map-cue') ||
    document.getElementById('root-booth-map-standalone');

if (CONTAINER === null) {
    throw new Error('Element #root not found');
}

const ROOT = createRoot(CONTAINER);

ROOT.render(
    <React.StrictMode>
        <AppRoot />
    </React.StrictMode>
);
