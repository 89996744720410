/* eslint-disable prettier/prettier,import/no-mutable-exports */
import { FitBoundsOptions, LatLngExpression } from 'leaflet';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { ConstantsConfig } from '../core/interfaces';

// --------- CAN BE OVERWRITTEN IN CONFIG.JSON! ---------
export let originalImg = { y: 11520, x: 11520 };
export let tileSize = 258;

// PROJECTION SETTINGS:
export const angleInitialXRad = Math.PI / 8;
export let distance = 80;
export const fovRad = 1; // radians = ~57 degrees.
export const intersectionHeightM = 1.5;
export const pointFocus = new Vector3(0, 0, 0);

// Possibly not needed.
export const markerScalingFactorMobile = 1;
// Used for duplicate markers, who's positions need to be spread out around a circle.
export const shiftDirectionScalingFactor = 0.08;

export const colorTextLight = '#FFFFFF';
export const colorTextDark = '#000028';
export const colorTourPaths = '#00FFB9';

export let empty = ((originalImg.x - originalImg.y) / 2 / originalImg.x) * tileSize;
export let defaultMarkerSize = 30;
export let defaultMarkerSizeTour = 30;
export let defaultMarkerSizeCluster = 30;
export let defaultMarkerSizeDesktopLaptop = 30;
export let defaultMarkerSizeTourDesktopLaptop = 30;
export let defaultMarkerSizeClusterDesktopLaptop = 30;
export const hoverIncrease = 10;
export let initialZoom = 1;
export let minZoom = 1;
export let maxZoom = 6;
export let dontClusterZoom = 1;
export let initialCenter = [-tileSize / 2, tileSize / 2] as LatLngExpression;
export let initialBounds = {
    tl: { x: 2700, y: 4100 },
    br: { x: 8720, y: 6920 },
};
export let animate = !window.matchMedia('(prefers-reduced-motion: reduce)').matches;
export let clusteringEps = Math.min(originalImg.x, originalImg.y) / 3;
export let clusteringMinPts = 10;
export let flyToBoundsOption = {
    duration: animate ? 1 : 0,
    padding: [4 * defaultMarkerSize, 4 * defaultMarkerSize],
} as FitBoundsOptions;
export let showArrowHeadsZoom = 2;
export let timeoutSearchString = 30000; // 30s
export let timeoutTrackSearchString = 2000; // 2s
export const timeoutReset = 2 * 60 * 1000; // 2 min

export let contentPageList = [
    'innovationStages',
    'congress',
];
export let contentPageImages: { [key: string]: string } = {
    innovationStages: 'tiles_map/ACH24/Innovation_Stages_map.png',
    congress: 'tiles_map/ACH24/Congress_map.png',
};

export function updateConstants(constantsConfig: ConstantsConfig): void {
    originalImg = constantsConfig.originalImg ?? originalImg;
    tileSize = constantsConfig.tileSize ?? tileSize;
    distance = constantsConfig.defaultDistance ?? distance;
    empty = constantsConfig.empty ?? empty;
    defaultMarkerSize = constantsConfig.defaultMarkerSize ?? defaultMarkerSize;
    defaultMarkerSizeTour = constantsConfig.defaultMarkerSizeTour ?? defaultMarkerSizeTour;
    defaultMarkerSizeCluster = constantsConfig.defaultMarkerSizeCluster ?? defaultMarkerSizeCluster;
    defaultMarkerSizeDesktopLaptop = constantsConfig.defaultMarkerSizeDesktopLaptop ?? defaultMarkerSizeDesktopLaptop;
    defaultMarkerSizeTourDesktopLaptop = constantsConfig.defaultMarkerSizeTourDesktopLaptop ?? defaultMarkerSizeTourDesktopLaptop;
    defaultMarkerSizeClusterDesktopLaptop = constantsConfig.defaultMarkerSizeClusterDesktopLaptop ?? defaultMarkerSizeClusterDesktopLaptop;
    initialZoom = constantsConfig.initialZoom ?? initialZoom;
    minZoom = constantsConfig.minZoom ?? minZoom;
    maxZoom = constantsConfig.maxZoom ?? maxZoom;
    dontClusterZoom = constantsConfig.dontClusterZoom ?? dontClusterZoom;
    initialCenter = constantsConfig.initialCenter ?? initialCenter;
    initialBounds = constantsConfig.initialBounds ?? initialBounds;
    animate = constantsConfig.animate ?? animate;
    clusteringEps = constantsConfig.clusteringEps ?? clusteringEps;
    clusteringMinPts = constantsConfig.clusteringMinPts ?? clusteringMinPts;
    flyToBoundsOption = constantsConfig.flyToBoundsOption ?? flyToBoundsOption;
    showArrowHeadsZoom = constantsConfig.showArrowHeadsZoom ?? showArrowHeadsZoom;
    timeoutSearchString = constantsConfig.timeoutSearchString ?? timeoutSearchString;
    timeoutTrackSearchString = constantsConfig.timeoutTrackSearchString ?? timeoutTrackSearchString;
    contentPageList = constantsConfig.contentPageList ?? contentPageList;
    contentPageImages = constantsConfig.contentPageImages ?? contentPageImages;
}
