const TRANSLATIONS: {
    [key: string]: {
        [key: string]: string;
    };
} = {
    'de-DE': {
        appInfo_appname: 'Booth Navigator',
        appInfo_commit: 'Commit',
        appInfo_date: 'Datum',
        appInfo_version: 'Version',
        languageEnglishName: 'German',
        languageNativeName: 'Deutsch',
        languages: 'Sprachen',
        search: 'Suche',
        menuBtn: 'Burger Menü',
        menu: 'Menü',
        toggleDimensions: 'Wechsel 2D/3D Ansicht',
        fullscreenClose: 'Fullscreen schließen',
        fullscreenOpen: 'Fullscreen öffnen',
        reset: 'Zurücksetzen',
        legend: 'Legende',
        close: 'Schließen',
        boothPlan: 'Standplan',
        tours: 'Rundgänge',
        shareQR: 'QR Code teilen',
        boothInfoLink: 'Mehr Informationen',
        ok: 'OK',
        searchSuggestions: 'Vorschläge',
        searchResults: 'Suchergebnisse',
        start: 'Start',
        end: 'Ende',
        meetingRooms: 'Meetingräume',
        meetingRoomsText: 'Finden Sie uns in der Frankenhalle, Halle 11.0.',
        itr24Outdoor: 'Freigelände und weitere Stände',
        itr24OutdoorText: 
        '<h6>Fahrzeuge</h6><br/>' +
        '<p>Im Jahr 2024 stellen wir unsere Nahverkehrs-, Push-Pull- und Hochgeschwindigkeitsangebote für Europa und Ägypten vor. Entdecken Sie den Velaro für Ägypten, den Mireo Smart und den Vectrain im Außenbereich auf den Gleisen 6 und 7. Werfen Sie einen genaueren Blick auf unsere neue digitale Smart Asset Suite für Vectron-Lokomotiven.</p><p>Siemens Mobility liefert komplette Lösungen für die Bahnelektrifizierung, einschließlich Beratung, Planung und Lieferung von Equipment, die ein Höchstmaß an Integration gewährleisten. Besuchen Sie unsere Sitras Produkte und Lösungen im Rail Electrification Container.</p><br/>' +
        '<h6>Bahnkomponenten</h6><br/>' +
        '<p>Erfolg auf der Schiene braucht die richtigen Komponenten und wettbewerbsfähige Technologien. Siemens Mobility präsentiert auf der InnoTrans 2024 sein maßgeschneidertes Komponentenportfolio für Zughersteller und stellt die neuesten Entwicklungen auf einem eigenen Stand in Halle 27 vor (Stand 220).</p><br/>' +
        '<h6>Smart Train Lease</h6><br/>' +
        '<p>Siemens Mobility hat seine Tochtergesellschaft Smart Train Lease GmbH gegründet, um Kunden die Möglichkeit zu geben, ihre Flotten flexibel mit gemieteten modernen Batterie-, Wasserstoff- und Elektrotriebzügen zu ergänzen. Die Mireo Smart-Züge von Siemens Mobility sind kurzfristig verfügbar, für den Betrieb bereits zugelassen und erfüllen alle geforderten Standards für den modernen Personennahverkehr. Mit vorkonfigurierten Fahrzeugen und zusätzlichen Dienstleistungen wie der Instandhaltung bietet dieses neue Angebot eine wirtschaftliche Alternative zur schnellen und flexiblen Flottenerweiterung. Darüber hinaus ermöglicht es den Kunden, neue, nachhaltige Technologien unkompliziert zu testen. Die Smart Train Lease GmbH bietet dieses Mietmodell zunächst innerhalb von Deutschland an. Mittelfristig ist eine Ausweitung auf ganz Europa geplant.</p>',
        satelliteBooths: 'Weitere Hallen',
        satelliteBoothsText:
            '<h6>Halle 2 - C54 - @Young Talents</h6><br/>' +
            '<p>Tauche ein in die Vielfalt der Berufsmöglichkeiten bei Siemens, vorgestellt von erfahrenen Mitarbeitenden und Recruiter*innen.</p><p>Sie gewähren dir einen Einblick in verschiedene Karrierewege, den Bewerbungsprozess und Einstiegsmöglichkeiten und stehen bereit, um all deine Fragen zu beantworten.</p><br/>' +
            '<h6>Halle 12 - D40 @Partner Country Pavillon Norway</h6><br/>' +
            '<p>Unternehmen können die heutigen Herausforderungen nicht alleine bewältigen. Wenn sie aber auf ihrem Weg zum Digital Enterprise mit anderen im Ecosystem zusammenarbeiten, werden sie die digitale Transformation schneller und leichter umsetzen. Siemens legt Wert auf eine transparente, sichere und offene Kooperation mit Unternehmen aller Größen und Branchen, um gemeinsam innovative Lösungen zu entwickeln.</p><br/>' +
            '<h6>Halle 13 - Hydrogen</h6><br/>' +
            '<p>Als Anbieter von Kernkomponenten für Elektrifizierung, Automatisierung und Digitalisierung, bedienen wir mit unserem wasserstofffähigen Portfolio Prozess-OEM-, EPC- und Endkunden mit Soft- und Hardware, um Ausrüstungsmodule oder ganze Anlagen entlang der Wasserstoff-Wertschöpfungskette zu planen, zu bauen und zu betreiben - von der H2-Produktion über die Umwandlung, Speicherung und den Transport bis hin zur Nutzung.</p><br/>' +
            '<h6>Halle 14 - H06 - @Industrial 5G Arena</h6><br/>' +
            '<p>Industrial 5G öffnet die Tür zu einer umfassenden drahtlosen Vernetzung von Produktion, Instandhaltung und Logistik. Die zuverlässige und deterministische Breitbandübertragung wird eine deutliche Steigerung der Effizienz und Flexibilität in der industriellen Wertschöpfung ermöglichen. Das Industrial 5G-Ökosystem von Siemens wurde speziell für OT-Anforderungen entwickelt und ist ein wichtiger Baustein auf dem Weg zur digitalen Transformation.</p>',
        innovationStages: 'Innovation Stages',
        innovationStagesText:
            '<h3>4.1 - Pharma Innovation Stage</h3>' +
            '<h3>6.0 - Hydrogen Innovation Stage</h3>' +
            '<h3>6.0 - Green Innovation Stage</h3>' +
            '<h3>9.0 - Process Innovation Stage</h3>' +
            '<h3>11.0 - Digital Innovation Stage</h3>' +
            '<h3>12.0 - Lab Innovation Stage</h3>',
        congress: 'Congress',
        congressText:
            '<h3>Portalhaus Room Frequenz 1</h3>' +
            '<h3>Portalhaus Room Frequenz 2</h3>' +
            '<h3>4.0 - Room Europa</h3>' +
            '<h3>4C - Room Alliance</h3>' +
            '<h3>4C - Room Consens</h3>' +
            '<h3>4C - Room Concorde</h3>' +
            '<h3>5.0 - Room Encounter</h3>' +
            '<h3>8.1 - Room Symmetrie 2</h3>' +
            '<h3>11.0 - Room Granat</h3>' +
            '<h3>11.0 - Room Korall</h3>',
        navigatorToGo: 'Booth Navigator zum Mitnehmen',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        flags: {
            newproduct: 'Neues Produkt',
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        loadingTexts: {
            0: '',
            2: 'Messestand wird geladen...',
            5: 'Messestand wird geladen - längere Ladezeit...',
        },
        previewText:
            '<br/>Für mehr Informationen besuchen Sie die Seite ab dem <b>DATE</b> wieder.',
        previewTextFallback:
            '<br/>Für mehr Informationen besuchen Sie die Seite später wieder.',
        podcastBtn: 'Video Galerie',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        dateFormat: {
            monthNames: [
                'Januar',
                'Februar',
                'März',
                'April',
                'Mai',
                'Juni',
                'Juli',
                'August',
                'September',
                'Oktober',
                'November',
                'Dezember',
            ],
            dayOrdinalSuffixes: [
                '1.',
                '2.',
                '3.',
                '4.',
                '5.',
                '6.',
                '7.',
                '8.',
                '9.',
                '10.',
                '11.',
                '12.',
                '13.',
                '14.',
                '15.',
                '16.',
                '17.',
                '18.',
                '19.',
                '20.',
                '21.',
                '22.',
                '23.',
                '24.',
                '25.',
                '26.',
                '27.',
                '28.',
                '29.',
                '30.',
                '31.',
            ],
        },
        errorConfig: 'Zur Zeit sind keine Messedaten verfügbar.',
    },
    'en-US': {
        appInfo_appname: 'Booth Navigator',
        appInfo_commit: 'Commit',
        appInfo_date: 'Date',
        appInfo_version: 'Version',
        languageEnglishName: 'English',
        languageNativeName: 'English',
        languages: 'Languages',
        search: 'Search',
        menuBtn: 'Burger Menu',
        menu: 'Menu',
        toggleDimensions: 'Toggle 2D/3D view',
        fullscreenClose: 'Fullscreen schließen',
        fullscreenOpen: 'Fullscreen öffnen',
        reset: 'Reset',
        legend: 'Legend',
        close: 'Close',
        boothPlan: 'Booths',
        tours: 'Tours',
        shareQR: 'Share QR Code',
        boothInfoLink: 'More information',
        ok: 'OK',
        searchSuggestions: 'Suggestions',
        searchResults: 'Search results',
        start: 'Start',
        end: 'End',
        itr24Outdoor: 'Outdoor area and other stands',
        itr24OutdoorText: 
        '<h6>Vehicles</h6><br/>' +
        '<p>In 2024, we highlight our commuter, push-pull and high-speed offerings for Europe and Egypt.</p><p>Explore the Velaro for Egypt, the Mireo Smart, the Vectrain in the outdoor area on tracks 6 and 7. Take a closer look into our new digital Smart Asset Suite for Vectron Locomotives.</p><p>Siemens Mobility delivers entire rail electrification solutions including consultation, planning, and equipment supply ensuring the highest level of integration. Visit our Sitras products and solutions at the Rail Electrification container.</p><br/>' +
        '<h6>Railway Components</h6><br/>' +
        '<p>Success on rails needs the right components, and competitive technologies. Siemens Mobility will present its tailored component portfolio for train manufacturers at InnoTrans 2024, showcasing the latest developments at a separate booth in Hall 27, Stand 220.</p><br/>' +
        '<h6>Smart Train Lease</h6><br/>' +
        '<p>Siemens Mobility has founded the subsidiary Smart Train Lease GmbH to enable customers to flexibly supplement their fleets with rented state-of-the-art battery, hydrogen, and electric multiple-unit trains. The Mireo Smart trains from Siemens Mobility are available at short notice, approved for operation, and meet all required standards for modern regional passenger transport. By providing preconfigured trains and additional services such as maintenance, this new offer provides an economical alternative for quickly and flexibly expanding fleets. It also enables customers to easily test innovative and sustainable rail technologies. Smart Train Lease GmbH is initially offering this rental model in Germany and plans to expand it throughout Europe in the medium term.</p>',
        meetingRooms: 'Meeting Rooms',
        meetingRoomsText: 'Find us in the Frankenhalle, Hall 11.0.',
        satelliteBooths: 'Satellite Booths',
        satelliteBoothsText:
            '<h6>Halle 2 - C54 - @Young Talents</h6><br/>' +
            '<p>Dive into the diversity of career opportunities at Siemens, presented by experienced employees and recruiters.</p><p>They provide you with insights into various career paths, the application process, and entry opportunities, and are ready to answer all your questions.</p><br/>' +
            '<h6>Halle 12 - D40 @Partner Country Pavillon Norway</h6><br/>' +
            '<p>Individual companies can no longer overcome today’s challenges on their own. By working with others in the ecosystem on their path to becoming a Digital Enterprise, they’ll implement the digital transformation faster/easier. This requires a new mindset: cooperation instead of “every company for itself,” leading to a safe, secure, and transparent collaboration. Siemens makes this a reality with a focus on the joint development of innovative solutions, regardless of a company’s size or industry.</p><br/>' +
            '<h6>Halle 13 - Hydrogen</h6><br/>' +
            '<p>As a provider of core components for electrification, automation, and digitalization, we serve process OEMs, EPCs, and end customers with our hydrogen-capable portfolio of software and hardware to plan, build, and operate equipment modules or entire facilities along the hydrogen value chain—from H2 production to conversion, storage, transportation, and utilization.</p><br/>' +
            '<h6>Halle 14 - H06 - @Industrial 5G Arena</h6><br/>' +
            '<p>Industrial 5G opens the door to comprehensive wireless networking of production, maintenance and logistics. Reliable and deterministic broadband transmission will enable a significant increase in efficiency and flexibility in industrial value creation. The Industrial 5G ecosystem by Siemens was specifically developed for OT needs and is an important building block on the way to digital transformation.</p>',
        innovationStages: 'Innovation Stages',
        innovationStagesText:
            '<h3>4.1 - Pharma Innovation Stage</h3>' +
            '<h3>6.0 - Hydrogen Innovation Stage</h3>' +
            '<h3>6.0 - Green Innovation Stage</h3>' +
            '<h3>9.0 - Process Innovation Stage</h3>' +
            '<h3>11.0 - Digital Innovation Stage</h3>' +
            '<h3>12.0 - Lab Innovation Stage</h3>',
        congress: 'Congress',
        congressText:
            '<h3>Portalhaus Room Frequenz 1</h3>' +
            '<h3>Portalhaus Room Frequenz 2</h3>' +
            '<h3>4.0 - Room Europa</h3>' +
            '<h3>4C - Room Alliance</h3>' +
            '<h3>4C - Room Consens</h3>' +
            '<h3>4C - Room Concorde</h3>' +
            '<h3>5.0 - Room Encounter</h3>' +
            '<h3>8.1 - Room Symmetrie 2</h3>' +
            '<h3>11.0 - Room Granat</h3>' +
            '<h3>11.0 - Room Korall</h3>',
        portraitHeaderH2: 'Booth Navigator',
        portraitHeaderP: 'Find your way around',
        navigatorToGo: 'Booth Navigator to Go',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        flags: {
            newproduct: 'New Product',
        },

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        loadingTexts: {
            0: '',
            2: 'Loading booth...',
            5: 'Loading booth - longer loading time...',
        },
        previewText:
            '<br/>For more information, visit the page again from <b>DATE</b>.',
        previewTextFallback:
            '<br/>For more information, visit the page again later.',
        podcastBtn: 'Video Gallery',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        dateFormat: {
            monthNames: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
            dayOrdinalSuffixes: [
                '1st',
                '2nd',
                '3rd',
                '4th',
                '5th',
                '6th',
                '7th',
                '8th',
                '9th',
                '10th',
                '11th',
                '12th',
                '13th',
                '14th',
                '15th',
                '16th',
                '17th',
                '18th',
                '19th',
                '20th',
                '21st',
                '22nd',
                '23rd',
                '24th',
                '25th',
                '26th',
                '27th',
                '28th',
                '29th',
                '30th',
                '31st',
            ],
            monthFirst: 'true',
        },
        errorConfig: 'No fair data is available at the moment.',
    },
};

export default TRANSLATIONS;
